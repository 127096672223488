import React from "react";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";
import { getBlogs } from "../api/utils";
import { useNavigate } from "react-router-dom";
import useFetch from "../api/hooks/useFetch";
import moment from "moment";

export default function Blogs() {
  const navigate = useNavigate();

  const { apiData, isLoading, error } = useFetch(getBlogs, {});

  const toBlog = (id) => {
    navigate(`/blog/${id}`, { state: { item: id } });
  };

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container text-center">
            <h1 className="tc-primary head-font">Blogs</h1>
            <h1 className="tc-primary fs-20">
              Insights, Trends & Innovations – Stay Ahead in Software
              Development
            </h1>
          </div>
        </section>
        <hr className="hr-double" />
        <section>
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12">
                {apiData?.map((blog, index) => {
                  return (
                    <a
                      onClick={() => {
                        toBlog(blog?.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <h2
                        className="tc-primary head-font2 sm-caps-none"
                        style={{ textAlign: "justify" }}
                      >
                        {blog?.title ?? "NA"}
                      </h2>
                      <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                        <strong className="tc-primary-light">
                          {blog?.author ?? "NA"}
                        </strong>
                        <span className="dot" />
                        <strong>
                          <strong className="tc-primary-light">
                            {moment(blog?.posted_at).fromNow() ?? "NA"}
                          </strong>
                        </strong>
                      </p>
                      {index < apiData.length - 1 ? (
                        <hr className="my-4" />
                      ) : null}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
